import React, {Component} from 'react';
import {
    Input,
    Form,
    FormGroup,
    Label,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table,
    UncontrolledCollapse,
    Row,
    Col
} from 'reactstrap';
import classes from './ConsentManager.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import {createMarkup} from '../../shared/utility';
import Cookies from 'js-cookie';
import axios from "../../axios-base";

class ConsentManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            switchTab: false,
            statisticCheck: Cookies.get('bigfoot_statistic-consent') === "true",
            personalizationCheck: Cookies.get('bigfoot_personalization-consent') === "true",
            performanceCheck: Cookies.get('bigfoot_performance-consent') === "true",
            linkAll: false,
            clientLoad: null,
            clientAction: null,
            scrollToHeadline: null,
        };
        this.tabHandler = this.tabHandler.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.selectionConsentHandler = this.selectionConsentHandler.bind(this);
        this.completeConsentHandler = this.completeConsentHandler.bind(this);
        this.clientActionHandler = this.clientActionHandler.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
    };

    tabHandler = (tab, scrollTarget) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                switchTab: true
            });
        }
    };

    inputChangeHandler = (event) => {
        const target = event.target;
        const name = target.name;
        const checked = target.checked;

        this.setState({
            [name]: checked
        });
    };

    selectionConsentHandler = async() => {
        let date = Date.now();
        await this.setState({
            clientAction: date
        });
        Cookies.set("bigfoot_cookie-consent", true, {expires: 180});
        this.props.consentCallback(this.state);
        axios.post('/analytics/', this.state)
            .then(response => {
                console.debug(response);
                window.location.reload(true);
            })
            .catch(error => {
                console.debug(error);
            });
    };

    completeConsentHandler = async() => {
        let date = Date.now();
        await this.setState({
            statisticCheck: true,
            personalizationCheck: true,
            performanceCheck: true,
            linkAll: true,
            clientAction: date
        });
        Cookies.set("bigfoot_cookie-consent", true, {expires: 180});
        this.props.consentCallback(this.state);
        axios.post('/analytics/', this.state)
            .then(response => {
                console.debug(response);
                window.location.reload(true);
            })
            .catch(error => {
                console.debug(error);
            });
    };

    clientActionHandler = () => {
        let date = Date.now();
        this.setState({
            clientAction: date
        });
    };

    handleLinkClick(headlineId) {
        this.tabHandler('2');
        this.setState({
            scrollToHeadline: headlineId,
        });
    }

    scrollIfRequired() {
        const { scrollToHeadline } = this.state;

        if (scrollToHeadline) {
            setTimeout(() => {
                const headlineElement = document.getElementById(scrollToHeadline);

                if (headlineElement) {
                    headlineElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start', // Scroll to the top of the element
                        inline: 'nearest', // Keep the element in view
                    });
                    this.setState({
                        scrollToHeadline: null,
                    });
                }
            }, 100); // Adjust the delay time as needed
        }
    }

    componentDidMount() {
        let date = Date.now();
        this.setState({
            clientLoad: date
        });
        this.scrollIfRequired();
    }

    componentDidUpdate() {
        this.scrollIfRequired();
    }

    render() {
        let privacyPolicyPage = null;
        let privacyPolicyPageMarkup = null;

        if (this.props.pages) {
            privacyPolicyPage = this.props.pages.filter((page) => page.slug === "datenschutz")[0];
            privacyPolicyPageMarkup = privacyPolicyPage.body;
        }
        return (
            <React.Fragment>
                <ModalHeader tag='div' className="border-bottom-0">
                    <h4>Ihre Datenschutzpräferenzen</h4>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className="anl_cmt--tab--cookies" href="#" onClick={() => {
                                this.tabHandler('1');
                            }} active={this.state.activeTab === '1'}>Cookies</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="anl_cmt--tab--privacy" href="#" to="/datenschutz/" tag={NavLink} onClick={() => {
                                this.tabHandler('2');
                            }} active={this.state.activeTab === '2'}>Datenschutzerklärung</NavLink>
                        </NavItem>
                    </Nav>
                </ModalHeader>
                <ModalBody className="pt-1">
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <p>
                                Wir setzen Cookies und andere Technologien ein, um Ihnen das bestmögliche Nutzungs&shy;erlebnis
                                zu gewährleisten. Im Folgenden haben Sie die Möglichkeit Ihre persönlichen
                                Daten&shy;schutz&shy;präferenzen festzulegen.
                            </p>
                            <Form>
                                <FormGroup switch className="form-group mb-2 px-1 rounded bg-light">
                                    <Row className="p-2">
                                        <Col id="togglerNecessary">
                                            <FontAwesomeIcon icon={faAngleDown} size="lg" className="text-primary d-inline me-2"/>
                                            <h5 className="m-0 text-primary d-inline">Notwendig</h5>
                                        </Col>
                                        <Col xs="auto">
                                            <Label for="necessaryCheck" className="text-muted" check>
                                                <Input
                                                    name="necessaryCheck"
                                                    id="necessaryCheck"
                                                    type="switch"
                                                    role="switch"
                                                    checked
                                                    disabled/>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <UncontrolledCollapse toggler="#togglerNecessary">
                                    <p className="text-muted mb-1 p-2">Notwendige Technologien sind zur Funktion unserer
                                        Website erforderlich und können nicht deaktiviert werden. Cookies dieser Kategorie
                                        werden als Reaktion auf von Ihnen getätigten Aktionen gesetzt, die einer Dienstanforderung
                                        entsprechen, wie etwa dem Festlegen Ihrer Datenschutzeinstellungen.</p>
                                        <Table size="sm" borderless>
                                            <tbody>
                                                <tr className={classes.ConsentManagerTable}>
                                                    <th className="w-25" scope="row">Dienst</th>
                                                    <td className="px-0">Voting-Wettbewerb</td>
                                                </tr>
                                                <tr>
                                                    <th className="w-25" scope="row">Anbieter</th>
                                                    <td className="px-0">Social Value GmbH</td>
                                                </tr>
                                                <tr>
                                                    <th className="w-25" scope="row">Daten</th>
                                                    <td className="px-0">Ihre Nutzerpräferenzen</td>
                                                </tr>
                                                <tr>
                                                    <th className="w-25" scope="row">Cookies</th>
                                                    <td className="px-0 text-monospace">
                                                        <span className="me-1 badge rounded-pill text-bg-warning">bigfoot_cookie-consent</span><span className="me-1 badge rounded-pill text-bg-warning">bigfoot_functional-consent</span><span className="me-1 badge rounded-pill text-bg-warning">bigfoot_statistic-consent</span><span className="me-1 badge rounded-pill text-bg-warning">bigfoot_personalization-consent</span><span className="me-1 badge rounded-pill text-bg-warning">bigfoot_code-requested</span><span className="me-1 badge rounded-pill text-bg-warning">bigfoot_tour-projects</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="w-25" scope="row">Grundlage</th>
                                                    <td className="px-0">
                                                        Art. 6 Abs. 1 s. 1 lit. c DSGVO
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="w-25" scope="row">Verarbeitungsort</th>
                                                    <td className="px-0">
                                                        Paris (Frankreich)
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="w-25" scope="row">Verarbeitung Ihrer Daten</th>
                                                    <td className="px-0">
                                                        <a href="#" onClick={() => this.handleLinkClick('bigfoot-app')} className="anl_cmt--details--bigfoot-app">Details zur Weiterverarbeitung Ihrer Daten finden Sie hier</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </UncontrolledCollapse>
                                </FormGroup>

                                <FormGroup switch className="form-group mb-2 px-1 rounded bg-light">
                                    <Row className="p-2">
                                        <Col id="togglerStatistic">
                                            <FontAwesomeIcon icon={faAngleDown} size="lg" className="text-primary d-inline me-2"/>
                                            <h5 className="m-0 text-primary d-inline">Statistik</h5>
                                        </Col>
                                        <Col xs="auto">
                                            <Label for="statisticCheck" check>
                                                <Input
                                                    name="statisticCheck"
                                                    id="statisticCheck"
                                                    onChange={this.inputChangeHandler}
                                                    type="switch"
                                                    role="switch"
                                                    checked={this.state.statisticCheck}/>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <UncontrolledCollapse toggler="#togglerStatistic">
                                        <p className="mb-1 p-2">Mit diesen Cookies können wir Besuche zählen und Zugriffs&shy;quellen eruieren,
                                            um die Leistung unserer Website zu ermitteln und zu verbessern.</p>
                                        <Table size="sm" borderless>
                                            <tbody>
                                            <tr className={classes.ConsentManagerTable}>
                                                <th className="w-25" scope="row">Dienst</th>
                                                <td className="px-0">etracker Webanalyse</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Anbieter</th>
                                                <td className="px-0">etracker GmbH</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Daten</th>
                                                <td className="px-0">Besuchte Seiten, Endgerät, Browser, Betriebssystem, Referrer, Nutzerinteraktionen, IP-Adresse</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Cookies</th>
                                                <td className="px-0 text-monospace"><span className="me-1 badge rounded-pill text-bg-warning">BT_pdc</span><span className="me-1 badge rounded-pill text-bg-warning">BT_sdc</span><span className="me-1 badge rounded-pill text-bg-warning">et_oip</span><span className="me-1 badge rounded-pill text-bg-warning">_et_coid</span><span className="me-1 badge rounded-pill text-bg-warning">isSdEnabled</span></td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Grundlage</th>
                                                <td className="px-0">
                                                    Einwilligung, Art. 6 Abs. 1 lit. a DSGVO
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Verarbeitungsort</th>
                                                <td className="px-0">
                                                    Europäische Union
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Verarbeitung Ihrer Daten</th>
                                                <td className="px-0">
                                                    <a href="#" onClick={() => this.handleLinkClick('etracker-web-analyse')} className="anl_cmt--details--etracker-web-analytics">Details zur Weiterverarbeitung Ihrer Daten finden Sie hier</a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </UncontrolledCollapse>
                                </FormGroup>

                                <FormGroup switch className="form-group mb-2 px-1 rounded bg-light">
                                    <Row className="p-2">
                                        <Col id="togglerPersonalization">
                                            <FontAwesomeIcon icon={faAngleDown} size="lg" className="text-primary d-inline me-2"/>
                                            <h5 className="m-0 text-primary d-inline">Personalisierung</h5>
                                        </Col>
                                        <Col xs="auto">
                                            <Label for="personalizationCheck" check>
                                                <Input
                                                    name="personalizationCheck"
                                                    id="personalizationCheck"
                                                    onChange={this.inputChangeHandler}
                                                    type="switch"
                                                    role="switch"
                                                    checked={this.state.personalizationCheck}/>
                                            </Label>
                                        </Col>
                                    </Row>
                                    <UncontrolledCollapse toggler="#togglerPersonalization">
                                        <p className="p-2 mb-1 mt-3">Diese Technologien erlauben uns Ihr Nutzungs&shy;erlebnis zu personalisieren.
                                            Wir oder unseren Werbe&shy;partnern können dadurch ein Profil Ihrer Interessen
                                            erstellen um Ihnen zu ermöglichen die Darstellung dieser Website zu personalisieren
                                            oder um Ihnen relevante Werbung auf anderen Websites zu zeigen.</p>
                                        <Table size="sm" borderless>
                                            <tbody>
                                            <tr className={classes.ConsentManagerTable}>
                                                <th className="w-25" scope="row">Dienst</th>
                                                <td className="px-0">Adobe Fonts</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Anbieter</th>
                                                <td className="px-0">Adobe &nbsp;Inc.</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Daten</th>
                                                <td className="px-0">Schriftarten, Informationen zum anfordernden Server, Adobe-ID</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Cookies</th>
                                                <td className="px-0 text-monospace">Keine</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Grundlage</th>
                                                <td className="px-0">
                                                    Einwilligung, Art. 6 Abs. 1 lit. a DSGVO
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Verarbeitungsort</th>
                                                <td className="px-0">
                                                    Europäische Union, Vereinigte Staaten von Amerika
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-25" scope="row">Verarbeitung Ihrer Daten</th>
                                                <td className="px-0">
                                                    <a href="#" onClick={() => this.handleLinkClick('adobe-fonts')} className="anl_cmt--details--adobe-fonts">Details zur Weiterverarbeitung Ihrer Daten finden Sie hier</a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </UncontrolledCollapse>
                                </FormGroup>
                            </Form>
                        </TabPane>
                        <TabPane tabId='2'>
                            {privacyPolicyPageMarkup ?
                                <div dangerouslySetInnerHTML={createMarkup(privacyPolicyPageMarkup)}/> : "Loading"}
                            <div/>
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <Button className="anl_cmt--btn--accept-selection" onClick={this.selectionConsentHandler} outline color="dark" block>Auswahl Akzeptieren</Button>
                    <Button className="anl_cmt--btn--accept-all" onClick={this.completeConsentHandler} color="secondary" block>Alles Akzeptieren</Button>
                </ModalFooter>
            </React.Fragment>
        );
    }
}

export default ConsentManager;

Cookies.defaultProps = {
    disableStyles: false,
    hideOnAccept: true,
    acceptOnScroll: false,
    acceptOnScrollPercentage: 25,
    onAccept: () => {
    },
    cookieName: "bigfoot_cookie-consent",
    cookieValue: true,
    buttonText: "OK",
    debug: false,
    expires: 30,
    extraCookieOptions: {}
};
